import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: unknown, formatType: string): unknown {
    const dateTimeValue = value as DateTime;
    if (dateTimeValue && dateTimeValue.isValid) {
      return dateTimeValue.toFormat(formatType);
    }
    return null;
  }
}
