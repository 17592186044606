import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
import SignaturePad from 'signature_pad';

@Component({
  selector: 'gc-signature-input',
  templateUrl: './signature-input.component.html',
  styleUrls: ['./signature-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SignatureInputComponent }],
})
export class SignatureInputComponent implements AfterViewInit, ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  static nextId = 0;
  protected _value: string;
  protected _placeholer: string;
  protected _required: boolean;
  protected _disabled: boolean;
  protected _errorState = false;

  @HostBinding() id = `gc-signature-input-${SignatureInputComponent.nextId++}`;
  controlType = 'gc-signature-input';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @ViewChild('sigPad', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  signaturePad: SignaturePad;
  stateChanges = new Subject<void>();

  focused = false;
  onChange: (value: any) => void;
  onTouched: () => void;

  constructor(@Self() @Optional() public ngControl: NgControl, protected readonly elRef: ElementRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input() get placeholder() {
    return this._placeholer;
  }
  set placeholder(plh: string) {
    this._placeholer = plh;
    this.stateChanges.next();
  }

  set value(val: string) {
    this._value = val;
    if (this._value) {
      this.signaturePad?.fromDataURL(this._value);
    } else {
      this.signaturePad?.clear();
    }
    this.stateChanges.next();
  }
  get value(): string {
    return this._value;
  }

  get empty() {
    return !this._value;
  }

  get shouldLabelFloat() {
    return !this.empty;
  }

  @Input() get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  get errorState() {
    return this._errorState;
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
    this.signaturePad.addEventListener('endStroke', () => this.onSignatureEnd());
    this.signaturePad.addEventListener('beginStroke', () => this.onSignatureBegin());
    if (this._value) {
      if (this._disabled) {
        this.signaturePad.off();
      }
    }
    this.calculateSize();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onSignatureEnd() {
    const dataUrl = this.signaturePad.toDataURL('image/png');
    this._value = dataUrl;
    if (this.onChange) {
      this.onChange(this._value);
    }
    this._errorState = coerceBooleanProperty(this.ngControl?.touched && this.ngControl?.invalid);
    this.stateChanges.next();
  }

  onSignatureBegin() {
    if (this.canvas.nativeElement.width === 0) {
      this.calculateSize();
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.elRef.nativeElement.querySelector('canvas');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'canvas') {
      this.elRef.nativeElement.querySelector('canvas').focus();
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.calculateSize();
    this.clear();
  }

  calculateSize() {
    const canvas = this.canvas.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.fromDataURL(this._value);
  }

  clear() {
    this.signaturePad.clear();
    this._value = null;
    if (this.onChange) {
      this.onChange(this._value);
    }
    this._errorState = coerceBooleanProperty(this.ngControl?.touched && this.ngControl?.invalid);
    this.stateChanges.next();
    this.calculateSize();
  }

  writeValue(val: string) {
    this._value = val;
    this.signaturePad?.fromDataURL(val);
    this.stateChanges.next();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.stateChanges.next();
  }

  registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
}
