import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DoctorCardComponent } from '@components/doctor-card/doctor-card.component';
import { BookingType } from '@models/booking-type.model';
import { Doctor } from '@models/doctor.model';
import { DisplayService } from '@services/display.service';
import { DateTime } from 'luxon';
import { getDeviceTimezone } from 'src/app/timezones';

@Component({
  selector: 'gc-doctor-booking-card',
  templateUrl: './doctor-booking-card.component.html',
  styleUrls: ['./doctor-booking-card.component.scss'],
})
export class DoctorBookingCardComponent implements OnInit {
  @Input() doctor: Doctor;
  @Input() loadingTimeslots = true;
  maxTimeslotDisplay = 5;
  @ViewChild('doctorCard') doctorCard: DoctorCardComponent;

  constructor(private readonly disp: DisplayService) {}

  get shortestBookingType(): BookingType {
    return this.doctor.shortestBookingType;
  }

  get timeslots(): string[] {
    const doctorTimeslot = this.shortestBookingType?.earliestTimeslotDay.timeslots.slice(0, this.maxTimeslotDisplay) ?? [];

    const patientTimezone = getDeviceTimezone();
    // If we don't have a timezone for the doctor or the doctor's timezone is the same as the patient's timezone, return the timeslot as is
    if (doctorTimeslot.length < 1 || !this.doctor.timezoneJson?.timezone || this.doctor.timezoneJson?.timezone === patientTimezone) {
      return doctorTimeslot;
    }
    const temp: string[] = [];
    doctorTimeslot.forEach((timeslot) => {
      const originalDateTime = DateTime.fromFormat(
        `${this.shortestBookingType?.earliestTimeslotDay.date} ${timeslot}`,
        'yyyy-MM-dd HH:mm',
        { zone: this.doctor.timezoneJson?.timezone },
      );
      temp.push(originalDateTime.setZone(patientTimezone).toFormat('HH:mm'));
    });
    return temp ?? [];
  }

  get showTimezoneWarning(): boolean {
    return this.doctor.timezoneJson?.timezone && this.doctor.timezoneJson?.timezone !== getDeviceTimezone();
  }

  get bookingButtonTitle(): string {
    // If there is no shortest booking today, just show 'Make A Booking' button
    return this.shortestBookingType ? 'More...' : 'Make a Booking';
  }

  ngOnInit(): void {
    this.disp.isMobile$.subscribe((isMobile) => (this.maxTimeslotDisplay = isMobile ? 2 : 5));
  }

  getTimeslots() {
    this.loadingTimeslots = true;
    this.doctor.bookingTypes = [];
    this.doctorCard.updateTimeslots();
  }
}
