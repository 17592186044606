import { DateTime } from 'luxon';
// Converts a timezone in form '+HH:MM' or '-HH:MM' to minutes
export function timezoneOffsetConversion(offsetStr: string): number {
  const sign = offsetStr.startsWith('+') ? 1 : -1;
  const [hoursStr, minutesStr] = offsetStr.slice(1).split(':'); // Remove sign and split

  const hours = parseInt(hoursStr, 10) || 0;
  const minutes = parseInt(minutesStr, 10) || 0;

  const totalMinutes = sign * (hours * 60 + minutes);
  return totalMinutes;
}

export interface Timezone {
  country: string;
  city: string;
  abbr: string;
  offset: number;
  zone: string;
  isdst?: boolean; // is this Daylight Saving Time
  currentTime: string;
  text: string;
}

export interface TimezoneGroup {
  continent: string;
  timezones: Timezone[];
}

export function getDeviceTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convertTimeSlot(time: string, doctorTimezone = 'UTC', patientTimezone: string = getDeviceTimezone()): string {
  return DateTime.fromFormat(time, 'HH:mm', { zone: doctorTimezone }).setZone(patientTimezone).toFormat('HH:mm');
}
