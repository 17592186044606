import { Notification, NotificationData } from './notification.model';

export class BookingNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    this.actionName = 'View Booking';
    this.actionRequired = this.booking.date.diffNow().milliseconds <= 0;
    this.description = [
      'You',
      this.booking.date.diffNow().milliseconds >= 0 ? 'have an upcoming' : 'had a',
      'booking with',
      this.booking.doctor.fullName,
      'at',
      this.booking.date.toFormat('HH:mm'),
      'on',
      this.booking.date.toFormat('cccc, LL LLLL'),
    ].join(' ');
  }
}
