<div class="booking-display-card">
  <gc-profile-picture [imgSrc]="booking.user.profilePicture" [fullName]="booking.user.fullName"></gc-profile-picture>
  <div class="info-content">
    <span class="overline">{{ booking.date | customDate : 'HH:mm' }} on {{ booking.date | customDate : 'cccc, dd LLLL yyyy  (ZZ)' }}</span>
    <div class="info">
      <div class="names">
        <span class="patient-name">{{ booking.user.fullName }}</span>
        <span class="doctor-name">
          <div>
            <div class="booking-type-icon">
              <mat-icon [svgIcon]="displayIcon"></mat-icon>
            </div>
            <span>
              {{ booking.doctor.fullName || booking.doctor.diaryName }}
            </span>
          </div>
        </span>
      </div>
      <div class="actions">
        <a
          *ngIf="booking.doctor.landlines?.length"
          mat-icon-button
          matTooltip="Tel: {{ booking.doctor.landlines?.[0] }}"
          href="tel:{{ booking.doctor.landlines?.[0] }}"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>phone</mat-icon>
        </a>
        <a
          *ngIf="booking.doctor.physicalAddress"
          mat-icon-button
          matTooltip="View on map"
          [href]="booking.doctor.googleMapsLink"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>location_on</mat-icon>
        </a>
        <a
          *ngIf="booking.doctor.emailAddress"
          mat-icon-button
          matTooltip="Email"
          href="mailto:{{ booking.doctor.emailAddress }}"
          class="booking-display-card-button small"
          target="_blank"
        >
          <mat-icon>email</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
