<div class="body">
  <div class="content">
    <section>
      <h2>Notifications</h2>
      <gc-swipe-notifications></gc-swipe-notifications>
    </section>
    <section class="quick-action-collection">
      <h2>Quick Actions</h2>
      <mat-grid-list
        class="quick-actions"
        [cols]="quickActions.length <= 4 || (isMobile$ | async) ? 2 : 3"
        gutterSize="10px"
        rowHeight="110px"
      >
        <mat-grid-tile *ngFor="let action of quickActions">
          <button mat-button [routerLink]="action.path">
            <mat-icon [svgIcon]="action.icon"></mat-icon>
            <div [innerHTML]="action.label"></div>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </section>
    <section class="hide-on-mobile">
      <h2>Upcoming Bookings</h2>
      <div *ngIf="latestBookingList.length > 0; else loadingBookings" class="latest-bookings">
        <div *ngFor="let latestBooking of latestBookingList">
          <mat-card>
            <gc-booking-display-card [booking]="latestBooking"></gc-booking-display-card>
          </mat-card>
        </div>
        <div class="view-more uppercase">
          <span routerLink="/bookings/my-bookings">View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingBookings>
        <div class="loading" *ngIf="isLoadingBooking; else noBooking">
          <span>Loading bookings..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noBooking>
          <div>
            <div>No upcoming bookings</div>
          </div>
        </ng-template>
      </ng-template>
    </section>
    <section class="hide-on-mobile">
      <h2>My Saved Doctors</h2>
      <div *ngIf="doctor; else loadingDoctors">
        <div class="doctor-card">
          <gc-doctor-booking-card [doctor]="doctor"></gc-doctor-booking-card>
        </div>
        <div class="view-more uppercase">
          <span routerLink="/bookings/saved-doctors">View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingDoctors>
        <div class="loading" *ngIf="isLoadingDoctor; else noDoctor">
          <span>Loading doctors..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noDoctor>
          <div>
            <div>No saved doctors</div>
            <div class="view-more uppercase">
              <span routerLink="/bookings/find-a-doctor">Add a Doctor</span>
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </section>
    <section class="hide-on-mobile">
      <h2>Outstanding Payments</h2>
      <div *ngIf="!isLoadingPayment; else loadingPayments">
        <ng-container *ngIf="recentPayment; else noPayments">
          <mat-card [matBadge]="numberPayments" matBadgeColor="accent">
            <gc-payment-display-card [payment]="recentPayment"></gc-payment-display-card>
          </mat-card>
          <div class="view-more uppercase">
            <span routerLink="/payments">View More</span>
            <mat-icon svgIcon="chevron-right"></mat-icon>
          </div>
        </ng-container>
        <ng-template #noPayments>
          <div>No outstanding payments</div>
        </ng-template>
      </div>
      <ng-template #loadingPayments>
        <div class="loading" *ngIf="isLoadingPayment; else noPayment">
          <span>Loading payments..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noPayment>
          <div>No outstanding payments</div>
        </ng-template>
      </ng-template>
    </section>
    <section class="hide-on-mobile">
      <h2>Consent Forms</h2>
      <div *ngIf="!isLoadingConsent; else loadingConsents">
        <mat-card
          *ngIf="recentConsent; else noConsents"
          class="consent-card"
          [routerLink]="['/health', 'consent-forms', recentConsent.uuid]"
          [matBadge]="numberConsents"
          matBadgeColor="accent"
        >
          <mat-icon svgIcon="thumbs-up-down" style="height: 48px; width: 48px" color="primary"></mat-icon>
          <div class="consent-summary">
            <span class="mat-overline" style="font-size: 10px">{{ recentConsent.created | customDate : 'dd LLL yyyy' }}</span>
            <span class="consent-title">
              {{ recentConsent.healthWindow ? 'HW Consent' : 'Consent Form' }} from {{ recentConsent.doctor }}
            </span>
          </div>
        </mat-card>
        <ng-template #noConsents>
          <div>No outstanding consent forms</div>
        </ng-template>
        <div class="view-more uppercase" routerLink="/health/consent-forms">
          <span>View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingConsents>
        <div class="loading">
          <span>Loading consent forms...</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </ng-template>
    </section>
  </div>
</div>
