import { Component, OnDestroy, OnInit } from '@angular/core';
import { Payment } from '@models/account.model';
import { Booking } from '@models/booking.model';
import { ConsentForm } from '@models/consent-form.model';
import { Doctor } from '@models/doctor.model';
import { FamilyMember } from '@models/family-member.model';
import { Notification } from '@models/notifications/notification.model';
import { User } from '@models/user.model';
import { AccountService } from '@services/account.service';
import { BookingService } from '@services/booking.service';
import { ClinicalService } from '@services/clinical.service';
import { DisplayService } from '@services/display.service';
import { DoctorService } from '@services/doctor.service';
import { PwaInstallService } from '@services/pwa-install.service';
import { UserService } from '@services/user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mergeAll, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  // Labels and icons used in 'Quick Actions'
  quickActions = [
    {
      label: 'Make a new <span class="emphasize">Booking</span>',
      icon: 'calendar',
      path: '/bookings/',
    },
    {
      label: 'Complete <span class="emphasize">My Account</span>',
      icon: 'account-circle',
      path: '/setup/my-account/',
    },
    {
      label: 'Complete my <span class="emphasize">Medical Aid</span> info',
      icon: 'hospital-box',
      path: '/setup/medical-aid/',
    },
    {
      label: 'Setup my <span class="emphasize">Family</span>',
      icon: 'account-supervisor',
      path: '/setup/family-management/',
    },
    {
      label: 'See my <span class="emphasize">Bookings</span>',
      icon: 'calendar-today',
      path: '/bookings/my-bookings',
    },
    {
      label: 'View my <span class="emphasize">Payments</span>',
      icon: 'credit-card-outline',
      path: '/payments',
    },
    {
      label: 'View my <span class="emphasize">Documents</span>',
      icon: 'file-document-outline',
      path: '/health/clinical-documents',
    },
  ];
  notifications$: Observable<Notification[]>;
  unsubscribe$ = new Subject<void>();
  profile$: Observable<User>;
  family$: Observable<FamilyMember[]>;
  isMobile$: BehaviorSubject<boolean>;
  latestBooking: Booking;
  doctor: Doctor;
  numberPayments: number;
  recentPayment: Payment;
  numberConsents: number;
  recentConsent: ConsentForm;
  isLoadingPayment: boolean;
  isLoadingDoctor: boolean;
  isLoadingBooking: boolean;
  isLoadingConsent: boolean;
  latestBookingList: Booking[];

  constructor(
    private readonly accountService: AccountService,
    private readonly userService: UserService,
    private readonly display: DisplayService,
    private readonly bookingService: BookingService,
    private readonly doctorService: DoctorService,
    private readonly clinicalService: ClinicalService,
    public install: PwaInstallService,
  ) {}

  ngOnInit() {
    this.profile$ = this.userService.profile$;
    this.isMobile$ = this.display.isMobile$;
    this.isLoadingPayment = true;
    this.isLoadingDoctor = true;
    this.isLoadingBooking = true;
    this.latestBookingList = [];
    this.profile$
      .pipe(
        tap(() => (this.isLoadingConsent = true)),
        switchMap((user: User) => this.clinicalService.getConsentForms(user.id)),
        tap(
          () => (this.isLoadingConsent = false),
          () => (this.isLoadingConsent = false),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((consents) => {
        const uncompletedConsent = consents
          .filter((c) => !c.complete || c.revokable)
          .sort((a, b) => a.created.diff(b.created).milliseconds);
        this.recentConsent = uncompletedConsent[0];
        this.numberConsents = uncompletedConsent.length || 0;
      });
    this.doctorService
      .getSavedDoctors$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((doctors) => {
        this.isLoadingDoctor = false;
        this.doctor = doctors[doctors.length - 1]; // Last Touched Saved Doctor
        if (this.doctor) {
          this.doctorService.updateTimeslots([this.doctor]);
        }
      });
    this.accountService
      .getPayments$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((payments) => {
        this.isLoadingPayment = false;
        const unpaidPayments = payments
          .filter((x) => !x.paid) // Unpaid Payments
          .sort((a, b) => (a.invoiceDate < b.invoiceDate ? 1 : -1)); // Most Recent
        this.numberPayments = unpaidPayments?.length || 0;
        this.recentPayment = unpaidPayments[0];
      });
    this.userService.familyMembers$
      .pipe(
        mergeMap((familyMembers) => familyMembers.map((member) => this.bookingService.getBookings$(member.id))),
        mergeAll(),
        map((bookingsArr) => bookingsArr.reduce((bookings, currentBookings) => bookings.concat(currentBookings), [])),
      )
      .subscribe((bookings) => {
        this.isLoadingBooking = false;

        this.latestBooking = bookings
          .filter((x) => x.date.diffNow().milliseconds > 0) // Upcoming Bookings
          .sort((a, b) => (a.date > b.date ? 1 : -1))[0]; // Earliest Booking
        if (this.latestBooking) {
          const index = this.latestBookingList.findIndex((object) => object.uuid === this.latestBooking.uuid);
          if (index === -1) {
            this.latestBookingList.push(this.latestBooking);
            this.latestBookingList.sort((a, b) => (a.date < b.date ? -1 : 1));
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
