<form class="login-form" [formGroup]="loginForm" (submit)="login()">
  <mat-form-field>
    <mat-label>Email/Mobile No./ID No.</mat-label>
    <input matInput formControlName="identifier" name="identifier" autocomplete="username" gcFormField />
    <mat-error>Please enter an email/mobile no./ID no.</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Password</mat-label>
    <input
      matInput
      [type]="showPassword ? 'text' : 'password'"
      autocomplete="current-password"
      formControlName="password"
      name="password"
      autocomplete="current-password"
      gcFormField
    />
    <button mat-icon-button matSuffix type="button" *ngIf="loginForm.get('password').value" (click)="showPassword = !showPassword">
      <mat-icon [svgIcon]="showPassword ? 'eye-off' : 'eye'"></mat-icon>
    </button>
    <mat-error>Password is required</mat-error>
  </mat-form-field>

  <div class="padding-bottom">
    <mat-slide-toggle formControlName="remember">Keep me logged in</mat-slide-toggle>
  </div>
  <div class="actions">
    <button color="accent" name="feedback" mat-raised-button type="button" (click)="feedback()" class="hide-on-larger">
      <mat-icon svgIcon="comment-quote"></mat-icon>
      Feedback
    </button>

    <button
      class="login-btn"
      color="primary"
      mat-raised-button
      type="submit"
      [disabled]="loginForm.invalid"
      *ngIf="shouldLog; else noLogLogin"
      gcLogButton
      page="sign-up-step-2"
      [diaryUuid]="doctorUuid"
      context="standard sign in"
    >
      <mat-icon svgIcon="login"></mat-icon>
      <span>Log in</span>
    </button>
    <ng-template #noLogLogin>
      <button color="primary" mat-raised-button type="submit" [disabled]="loginForm.invalid">
        <mat-icon svgIcon="login"></mat-icon>
        <span>Log in</span>
      </button>
    </ng-template>
  </div>
  <div class="google-sign">
    <a mat-button class="forgot-password uppercase" (click)="forgetPasswordClick()">Forgot Password</a>
    <div class="divider"><span>OR</span></div>
    <gc-google-btn [buttonType]="'signin_with'" (googleButtonFunction)="signInWithGoogle()" #googleBtn></gc-google-btn>
  </div>
</form>
