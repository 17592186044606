<div class="cards" [style.zIndex]="maxRenderedCardCount" *ngIf="notifications.length; else loadingNotifications">
  <div
    #swipeCard
    (transitionend)="handleShift()"
    *ngFor="let notification of notifications.slice(0, maxRenderedCardCount); let i = index"
    class="card"
    [class.unread]="!notification.readDate"
    [class.action-required]="notification.actionRequired"
    [class.front-card]="i === 0"
    [ngStyle]="getCardStyle(i)"
  >
    <!-- Only render text details on top 2 cards -->
    <div *ngIf="notification && i < 2">
      <div class="card-info">
        <div>
          <mat-icon class="card-icon" [svgIcon]="notification.icon"></mat-icon>
        </div>
        <div class="card-text">
          <div class="card-date">{{ notification.sentDate | date : 'shortTime' }} on {{ notification.sentDate | date : 'fullDate' }}</div>
          <div class="card-message" [class.only-message]="notification.type === 'message'">
            {{ notification.description }}
          </div>
        </div>
        <div class="close-container">
          <button class="close" mat-icon-button (click)="dismissCard($event, notification)">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
      </div>
      <div class="action-container">
        <button class="action" mat-raised-button type="button" color="primary" (click)="doNotification(notification)">
          {{ notification.actionName || 'Dismiss' }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingNotifications>
  <div class="loading" *ngIf="isLoading; else noNotifications">
    <span>Loading notifications..</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-template #noNotifications>
    <div>No new notifications</div>
  </ng-template>
</ng-template>
