import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
@Pipe({
  name: 'todayTomorrow',
})
export class TodayTomorrowPipe implements PipeTransform {
  constructor(private readonly date: DatePipe) {} // eslint-disable-line  no-empty, @typescript-eslint/no-empty-function
  transform(value: string): string {
    try {
      const incommingDate = DateTime.fromISO(value).startOf('day');
      const today = DateTime.now().startOf('day');
      return !incommingDate.isValid
        ? value
        : today.hasSame(incommingDate, 'millisecond')
        ? 'Today'
        : today.plus({ day: 1 }).hasSame(incommingDate, 'millisecond')
        ? 'Tomorrow'
        : this.date.transform(value, 'dd MMM');
    } catch {
      return this.date.transform(value, 'dd MMM');
    }
  }
}
