<div class="top-container" [ngClass]="{ 'mini-menu': !fieldFocused && (scrollTop$ | async) > hideLength }">
  <div class="logo-backdrop"></div>
  <div class="user-profile" *ngIf="user$ | async as user; else loggedOut">
    <button mat-button type="button" routerLink="/setup/my-account" aria-label="Go to my account" [disableRipple]="true">
      <div class="profile-group">
        <gc-profile-picture class="user-profile-picture" [fullName]="user.fullName" [imgSrc]="user.profilePicture"></gc-profile-picture>
        <span>
          {{ user.fullName }}
        </span>
      </div>
    </button>
    <button mat-button type="button" routerLink="/dashboard" aria-label="Navigate to dashboard">
      <mat-icon svgIcon="home"></mat-icon>
      Dashboard
    </button>
  </div>
  <ng-template #loggedOut>
    <div class="login-buttons login-grids">
      <button mat-button type="button" routerLink="/register" aria-label="Sign up for your free myGC account">
        <mat-icon svgIcon="account-plus"></mat-icon>
        Sign Up
      </button>
      <button mat-button type="button" routerLink="/login" aria-label="Log in to your myGC account">
        <mat-icon svgIcon="login"></mat-icon>
        Log In
      </button>
    </div>
  </ng-template>
  <div class="img-container">
    <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" class="landing-logo" />
  </div>
  <form [formGroup]="searchForm" class="search-form form-grid" (submit)="search()">
    <mat-card class="search-card">
      <mat-card-title class="heading-2">Find and book a doctor near you,&nbsp;instantly!</mat-card-title>
      <mat-card-content class="location-group">
        <div class="location" [@infoBoxPopup]="locationFocused">
          <div class="location-info-box" *ngIf="locationFocused">
            Type "near me" and allow
            <b><i>myGC</i></b>
            to use your current location to locate a doctor near you.
          </div>
          <svg viewBox="0 0 100 100" class="corner" *ngIf="locationFocused">
            <path d="m 0 0 q 90 10 100 100 l 0 -100 l -100 0 z"></path>
          </svg>
          <mat-form-field style="position: relative">
            <mat-label>Location</mat-label>
            <mat-icon svgIcon="map-marker" matPrefix></mat-icon>
            <mat-icon
              svgIcon="crosshairs"
              matSuffix
              [color]="locationFocused ? 'primary' : undefined"
              [ngStyle]="{ transform: locationFocused ? 'scale(1)' : 'scale(0.667)' }"
              style="transition: transform 0.2s ease-in-out"
            ></mat-icon>
            <input
              matInput
              type="text"
              formControlName="location"
              #locationInput
              (focus)="onFocus('location')"
              (blur)="onBlur('location')"
              gcFormField
              gcFormFieldNoError
            />
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label>Speciality</mat-label>
          <input
            matInput
            formControlName="speciality"
            type="text"
            [matAutocomplete]="specialityAutocomplete"
            placeholder="Any speciality"
            gcFormField
            gcFormFieldNoError
            (focus)="onFocus('speciality')"
            (blur)="onBlur('speciality')"
          />
          <mat-icon svgIcon="medical-bag" matPrefix></mat-icon>
          <mat-autocomplete #specialityAutocomplete="matAutocomplete">
            <mat-option *ngFor="let speciality of filteredSpecialities$ | async" [value]="speciality">
              {{ speciality }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Search a name</mat-label>
          <mat-icon svgIcon="doctor" matPrefix></mat-icon>
          <input
            matInput
            (focus)="onFocus('name')"
            (blur)="onBlur('name')"
            type="text"
            formControlName="searchTerm"
            gcFormField
            gcFormFieldNoError
          />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <button mat-raised-button color="accent" class="big">
          <mat-icon svgIcon="magnify"></mat-icon>
          {{ searchForm.invalid ? 'Near Me' : 'Search' }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <div class="top-image landing-image">
    <img class="landing-people" src="assets/images/myGC-WLP-devices.png" alt="Mobile and desktop examples of myGC App" />
  </div>
</div>
<div class="menu-container" [ngClass]="{ 'top-margin': (scrollTop$ | async) > 100 }"></div>
<div class="features">
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <img src="assets/images/myGC-feature1.png" width="100%" alt="Image of a user searching for a doctor on myGC" />
      <div class="centered">
        <mat-card-title>
          <h2>Find and book a doctor near you, instantly!</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            With our easy search, you can quickly find a medical practitioner near you and immediately see their availability. Find a date
            and time that fits in your schedule and easily identify the different types of consultations each doctor offers.
          </p>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <div class="centered">
        <mat-card-title>
          <h2>Free, mahala, gratis!</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            Storing your medical history and data should not cost you anything. It's yours, after all! Share your personal and medical
            information with your doctor and receive your sick notes and scripts directly into your App. No limits, free forever.
          </p>
        </mat-card-content>
      </div>
      <img src="assets/images/myGC-feature2.png" width="100%" alt="Image of a user saving money in piggybank" />
    </div>
  </mat-card>
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <img src="assets/images/myGC-feature3.png" width="100%" alt="Image of a user video calling with a doctor on myGC" />
      <div class="centered">
        <mat-card-title>
          <h2>Telehealth video appointments with your doctor</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            In these trying times where you want to avoid exposure to COVID-19 as much as possible, we make doctors visits easy and
            accessible. Book a consultation ahead of time with your favourite medical practitioner or talk to a doctor available online
            right now.
          </p>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <div class="centered">
        <mat-card-title>
          <h2>Your medical history in the palm of your hand. Literally.</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            Can you still remember when last you had a Tetanus shot? What about the date of your last dental check-up?
            <span class="mygc-text">myGC</span>
            makes all of this information accessible in one central app. Just ask your doctor to share your medical data and documents with
            you via the App.
          </p>
        </mat-card-content>
      </div>
      <img src="assets/images/myGC-feature4.png" width="100%" alt="Image of a user with myGC on a big phone" />
    </div>
  </mat-card>
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <img src="assets/images/myGC-feature5.png" width="100%" alt="Image of a user happy to be able to pay with her card on her phone" />
      <div class="centered">
        <mat-card-title>
          <h2>Click, pay, done!</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            No more hassles trying to pay outstanding medical accounts. Now you can pay whenever and wherever you are. It's as easy as
            1-2-3: Click the link in the SMS, pay with your credit or debit card via our secure payment portal, and you're done!
          </p>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
  <mat-card class="feature-card">
    <div class="feature-card-grid">
      <div class="centered">
        <mat-card-title>
          <h2>Full control over your medical data and who you share it with</h2>
        </mat-card-title>
        <mat-card-content>
          <p>
            <strong><em>myGC</em></strong>
            prides itself on becoming fully POPIA compliant as well as taking all possible measures to ensure your data stays safe and
            secure. You also have full control over who you share your medical data with - your doctor, your spouse, your whole family, or
            nobody - it's entirely up to you.
          </p>
        </mat-card-content>
      </div>
      <img src="assets/images/myGC-feature6.png" width="100%" alt="Image of a user sharing his data on myGC" />
    </div>
  </mat-card>
</div>
<div class="bg-primary bg-colour-correct copyright">
  <div class="overhang-top bg-primary-darker">© Quant Solutions &amp; GoodX Software</div>
  <div class="overhang-top bg-primary-darker privacy-policy" (click)="showPrivacyPolicy()">View Privacy Policy</div>
  <div class="overhang-top bg-primary-darker privacy-policy" (click)="showAboutUs()">
    About
    <span class="mygc-text">myGC</span>
  </div>
</div>
