import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { DetailsCompleteGuard } from '@guards/details-complete.guard';
import { RedirectGuard } from '@guards/redirect.guard';
import { RegisterGuard } from '@guards/register.guard';
import { TitleGuard } from '@guards/title.guard';
import { VerifiedGuard } from '@guards/verified.guard';
import { TelehealthComponent } from '@modules/session/pages/telehealth/telehealth.component';
import { ForgotPasswordComponent } from '@modules/setup/pages/settings/forgot-password/forgot-password.component';
import { SettingsComponent } from '@modules/setup/pages/settings/settings.component';
import { AcceptInviteComponent } from '@pages/accept-invite/accept-invite.component';
import { BlockEmailChangeComponent } from '@pages/block-email-change/block-email-change.component';
import { ChangeEmailComponent } from '@pages/change-email/change-email.component';
import { CompleteDetailsComponent } from '@pages/complete-details/complete-details.component';
import { CompleteResetPasswordComponent } from '@pages/complete-reset-password/complete-reset-password.component';
import { LandingComponent } from '@pages/landing/landing.component';
import { LoginComponent } from '@pages/login/login.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { PrivacyPolicyComponent } from '@pages/privacy-policy/privacy-policy.component';
import { RecoverAccountComponent } from '@pages/recover-account/recover-account.component';
import { RegisterComponent } from '@pages/register/register.component';
import { VerifyEmailComponent } from '@pages/verify-email/verify-email.component';
import { WorkflowPdfComponent } from '@pages/workflow-pdf/workflow-pdf.component';
import { ConfirmBookingComponent } from '@modules/bookings/pages/confirm-booking/confirm-booking.component';
import { IdResolverService } from './resolvers/id-resolver.service';
import { LegacyUrlResolverService } from './resolvers/legacy-url-resolver.service';

export interface RouteSearchInfo {
  path: string;
  keywords: string[];
  items: { phrase: string; icon: string }[];
}

const routes: Routes = [
  {
    path: '',
    canActivateChild: [TitleGuard],
    children: [
      {
        path: 'bookings/confirm/:booking_uuid',
        resolve: {
          identifier: IdResolverService,
        },
        component: ConfirmBookingComponent,
        data: { title: 'Confirm Booking' },
      },
      {
        path: '',
        canActivate: [RedirectGuard, DetailsCompleteGuard, VerifiedGuard],
        loadChildren: async () => import('./modules/session/session.module').then((m) => m.SessionModule as any),
        data: { title: 'Home' },
      },
      {
        path: 'landing',
        component: LandingComponent,
        data: { title: 'Landing Page' },
      },
      {
        path: 'doctors',
        loadChildren: async () => import('./modules/doctor/doctor.module').then((m) => m.DoctorModule as any),
        data: { title: 'Doctors' },
      },
      {
        // Deal with legacy urls
        path: 'external-booking',
        component: LandingComponent,
        resolve: {
          identifier: LegacyUrlResolverService,
        },
        pathMatch: 'full',
      } as Route,
      {
        path: 'external',
        loadChildren: async () => import('./modules/doctor/doctor.module').then((m) => m.DoctorModule as any),
        data: { title: 'External Booking' },
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' },
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [RegisterGuard],
        data: { title: 'Register' },
      },
      {
        path: 'complete-details',
        component: CompleteDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Complete Registration' },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' },
      },
      {
        path: 'reset-password',
        component: CompleteResetPasswordComponent,
        data: { title: 'Reset Password' },
      },
      {
        path: 'setup/settings/change-password',
        component: SettingsComponent,
        data: {
          title: 'Reset Password',
          search_info: {
            keywords: ['change', 'password', 'reset', 'forgot'],
            items: [{ phrase: 'Reset/Change my password', icon: 'help-circle' }],
          } as RouteSearchInfo,
        },
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent,
        data: { title: 'Email Verification' },
      },
      {
        path: 'reset-email-change',
        component: BlockEmailChangeComponent,
        data: { title: 'Block Request' },
      },
      {
        path: 'change-email',
        component: ChangeEmailComponent,
        data: { title: 'Email Change Verification' },
      },
      {
        path: 'f/:hash',
        component: AcceptInviteComponent,
        data: { title: 'Accept Family Invite', redirectPath: 'login' },
        canActivate: [RedirectGuard],
      },
      {
        path: 'kiosk',
        component: LandingComponent,
        resolve: {
          identifier: LegacyUrlResolverService,
        },
        data: { title: 'Kiosk' },
      },
      {
        path: 't/:hash',
        component: LandingComponent,
        resolve: {
          identifier: LegacyUrlResolverService,
        },
        data: { title: 'Telehealth' },
      },
      {
        path: 'eth/:uuid', // External Telehealth
        component: TelehealthComponent,
        resolve: {
          identifier: IdResolverService,
        },
        runGuardsAndResolvers: 'always',
        data: { title: 'Telehealth', icon: 'message-video' },
      },
      {
        path: 'kiosk/:uuid',
        loadChildren: async () => import('./modules/kiosk/kiosk.module').then((m) => m.KioskModule as any),
        data: { title: 'Kiosk' },
      },
      {
        path: 'p/:uuid',
        loadChildren: async () => import('./modules/package/package.module').then((m) => m.PackageModule as any),
        data: { title: 'Package' },
      },
      {
        path: 'consent-form/:uuid',
        redirectTo: 'p/:uuid',
        data: { title: 'Consent Form' },
      },
      {
        path: 'questionnaire/:uuid',
        redirectTo: 'p/:uuid',
        data: { title: 'Questionnaire' },
      },
      {
        path: 'demographic-request/:uuid',
        redirectTo: 'p/:uuid',
        data: { title: 'Demographic Request' },
      },
      {
        path: 'medical-history/:uuid',
        redirectTo: 'p/:uuid',
        data: { title: 'Medical History' },
      },
      {
        path: 'pdf_event/:uuid',
        component: WorkflowPdfComponent,
        data: { title: 'Pdf Event', useCellphone: true },
        resolve: {
          identifier: IdResolverService,
        },
      },
      {
        path: 'recover-account',
        component: RecoverAccountComponent,
        data: { title: 'Recover Account' },
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent,
        data: { title: 'Privacy Policy' },
      },
      {
        path: '**',
        component: NotFoundComponent,
        data: { title: 'Page not Found' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export function getSearchableRoutes(theRoutes: Routes, root = ''): RouteSearchInfo[] {
  let results = [];
  theRoutes.forEach((route) => {
    const path = root + route.path;
    if (route.data?.search_info) {
      results.push({ path, ...route.data.search_info });
    }
    if (route.children) {
      results = results.concat(getSearchableRoutes(route.children, path + '/'));
    }
  });
  return results;
}

export const SEARCHABLE_ROUTES = getSearchableRoutes(routes);
