import { Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, LOCALE_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { LoggingService } from '@services/logging.service';
import { PwaInstallService } from '@services/pwa-install.service';
import { TagManagerService } from '@services/tag-manager.service';

import { UpdateService } from '@services/update.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('style.--vh')
  vh: string;
  @HostBinding('style.--mobiledashboard')
  mobiledashboard: string;
  unsubscribe$ = new Subject<void>();
  selectedLocale: string;
  showFestive = this.getFromLocalStorage('showFestive') === 'true' ? true : false;

  constructor(
    private readonly install: PwaInstallService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly updateService: UpdateService,
    private readonly logger: LoggingService,
    private readonly tagManagerService: TagManagerService,
    private readonly translate: TranslateService,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      this.mobiledashboard = this.router.url === '/dashboard' ? '72px' : '0px';
      if (event instanceof NavigationStart) {
        this.logger.addToJourney(event.url);
      } else if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          page: event.url,
        };
        this.tagManagerService.pushTag(gtmTag);
      }
    });

    this.updateService.updatesAvailable$
      .pipe(
        take(1),
        tap((hasUpdate) => {
          if (hasUpdate && !this.authService.isAuthenticated()) {
            const updateMessage =
              'myGC has had an update since you last visited. Do you want to update now? (It is recommended to update as soon as possible)';
            this.alertService
              .confirmDialog$('New updates available!', {
                detail: updateMessage,
              })
              .pipe(filter((result) => !!result))
              .subscribe(() => {
                this.updateService.activateUpdate();
              });
          }
        }),
      )
      .subscribe();

    this.selectedLocale = this.locale;
    this.translate.currentLang = this.selectedLocale;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: BeforeInstallPromptEvent) {
    event.preventDefault();
    this.install.deferredPrompt = event;
  }

  @HostListener('window:appinstalled')
  onAppInstalled() {
    this.install.installed = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.vh = event.target.innerHeight + 'px';
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.logger.logUserJourney$(event.target.URL, 'beforeunload').subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  saveToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  toggleFestiveIcons() {
    this.showFestive = !this.showFestive;
    this.saveToLocalStorage('showFestive', this.showFestive.toString());
  }

  getIcon() {
    const currentDate = new Date();
    if (currentDate.getMonth() === 11) {
      //Dec Christmas
      return '❅';
    } else if (currentDate.getMonth() === 0 && currentDate.getDate() === 1) {
      //New years
      return '✨';
    } else if (currentDate.getMonth() === 1 && currentDate.getDate() === 14) {
      //Valentines day
      return '❤️';
    }
  }
}
