<span class="payment text-style">
  <div class="invoice-info" style="width: fit-content; height: fit-content">
    <mat-icon [color]="payment.paid ? 'accent' : 'warn'" class="fit-icon">
      {{ payment.paid ? 'check_circle' : 'error_outline' }}
    </mat-icon>
    <div class="invoice-date">
      <span class="date">{{ payment.invoiceDate | date : 'fullDate' }}</span>
      <span class="description" [class.error]="!payment.paid">
        {{ payment.paymentAmount | currency : 'ZAR' : 'symbol-narrow' }} - {{ payment.treatingDoctor }}
      </span>
    </div>
  </div>
  <div class="actions">
    <button
      mat-flat-button
      [routerLink]="['/payments']"
      [queryParams]="{ hash: payment.hash }"
      [color]="payment.paid ? 'accent' : 'primary'"
      type="button"
    >
      {{ payment.paid ? 'View Invoice' : 'Pay Now' }}
    </button>
  </div>
</span>
