<div class="container" *ngIf="payment && payment.invoices && !payAllInvoices">
  <mat-card class="payment-card mat-elevation-z4">
    <mat-toolbar [class.unpaid]="!payment.paid" class="toolbar">
      <span>{{ payment.paid ? 'Paid' : 'Unpaid' }} Total:</span>
      <span>{{ payment.paymentAmount | currency : 'ZAR' : 'symbol-narrow' }}</span>
    </mat-toolbar>
    <mat-card-header>
      <gc-profile-picture mat-card-avatar [fullName]="payment.treatingDoctor"></gc-profile-picture>
      <mat-card-title>{{ payment.treatingDoctor }}</mat-card-title>
      <mat-card-subtitle>{{ payment.accountNumber }}</mat-card-subtitle>
    </mat-card-header>
    <button
      *ngIf="!payment.paid && onlyOnePaymentOption(payment)"
      mat-flat-button
      color="primary"
      (click)="openPayment(payment)"
      [style.background]="getBackground()"
      [style.color]="getFontColour()"
    >
      <mat-icon>payment</mat-icon>
      Pay All Outstanding
    </button>
    <button
      *ngIf="!payment.paid && !onlyOnePaymentOption(payment)"
      mat-flat-button
      color="primary"
      (click)="makePayment()"
      [style.background]="getBackground()"
      [style.color]="getFontColour()"
    >
      <mat-icon>payment</mat-icon>
      Pay All Outstanding
    </button>
  </mat-card>
  <div class="invoices-container">
    <div class="invoices mat-elevation-z0" *ngFor="let invoice of payment.invoices">
      <mat-accordion class="invoice-accordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="invoice-expansion-header">
            <span class="invoice-expansion-row text-style" [style.color]="hasTheme() === true ? 'black' : 'var(--primary-color)'">
              Invoice #{{ invoice.invoice_nr }}
            </span>
          </mat-expansion-panel-header>
          <span class="invoice-expansion-description">
            <div class="description-intro">
              <span><strong>Treatment date:</strong></span>
              <span>{{ invoice.treatment_date | date : 'shortDate' }}</span>
            </div>
            <div class="description-intro">
              <span><strong>Account number:</strong></span>
              <span>{{ payment.accountNumber }}</span>
            </div>
            <br />
            <mat-divider></mat-divider>
            <div class="mat-elevation-z1">
              <table mat-table [dataSource]="invoice.lines">
                <!-- Code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell class="description" *matHeaderCellDef>Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="description">
                  <th mat-header-cell class="description" *matHeaderCellDef>Item Description</th>
                  <td mat-cell *matCellDef="let element">{{ element.description }}</td>
                </ng-container>

                <!-- Total Column -->
                <ng-container matColumnDef="total">
                  <th mat-header-cell class="description" *matHeaderCellDef>Total</th>
                  <td mat-cell *matCellDef="let element">{{ element.payment_amount | currency : 'ZAR' : 'symbol-narrow' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['code', 'description', 'total']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['code', 'description', 'total']"></tr>
              </table>
            </div>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-toolbar color="primary" [style.background]="getBackground()" [style.color]="getFontColour()">
        <span>Total: {{ invoice.pat_amount | currency : 'ZAR' : 'symbol-narrow' }}</span>
      </mat-toolbar>
    </div>
  </div>
</div>
<div class="container" *ngIf="payment && !payment.invoices">
  <mat-card>
    <mat-card-title>Invoices</mat-card-title>
    <mat-card-subtitle>0 invoice lines</mat-card-subtitle>
  </mat-card>
</div>
<div>
  <div class="view-payment">
    <gc-view-payment-options [payment]="payment" [payAllInvoices]="payAllInvoices" url="/payments"></gc-view-payment-options>
  </div>
</div>
