<div [style.width]="width">
  <h1 mat-dialog-title class="heading-container" *ngIf="data.message">
    <mat-icon *ngIf="data.tag" [class]="data.tag" [svgIcon]="dialogIcon"></mat-icon>
    <span class="message">{{ data.message }}</span>
  </h1>
  <span mat-dialog-content *ngIf="data.detail" [innerHTML]="data.detail"></span>
  <div mat-dialog-actions class="confirm-button" *ngIf="!data.confirmation">
    <button mat-button mat-dialog-close [class]="data.tag" [color]="confirmColour" (click)="okayClick()">{{ confirmText }}</button>
  </div>
  <div mat-dialog-actions class="action-buttons" *ngIf="data.confirmation">
    <button mat-button [mat-dialog-close]="false" *ngIf="!data.hideCancel" class="cancel-button" [class]="data.tag" [color]="cancelColour">
      {{ cancelText }}
    </button>
    <button mat-button [mat-dialog-close]="true" [class]="data.tag" [color]="confirmColour">{{ confirmText }}</button>
  </div>
</div>
